import React from "react";
import {
  CCol,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react"

const ModalTermosUso = ({ modalVisible, setModalVisible }) => {
  return (
    <CModal
          alignment="center"
          size="lg"
          scrollable
          visible={modalVisible}
          onClose={() => setModalVisible(false)}
        >
          <CModalHeader>
            <CCol>
              <CModalTitle>Termos Gerais de Uso da Click Rifas</CModalTitle>
              <p className="fw-light mb-0 text-body-secondary">
                Versão atualizada em 01 de janeiro de 2024
              </p>
            </CCol>
          </CModalHeader>
          <CModalBody>
            <p className="fw-bold">
              Obrigado por utilizar os serviços da Click Rifas!
            </p>

            <p className="fw-bold">
              A Click Rifas oferece um espaço para quem deseja criar ou divulgar
              uma Rifa Online de forma simples e prática, possibilitando que as
              pessoas realizem os seus sonhos e os sonhos de outras pessoas.
              Estes Termos regem o uso da Plataforma Click Rifas e de todos os
              recursos, serviços, tecnologias e softwares disponibilizados pela
              Click Rifas, exceto quando esclarecemos expressamente que outros
              termos (e não estes) se aplicam.
            </p>

            <p className="fw-bold">
              Em especial, tratamos indistintamente como Plataforma Click Rifas
              ou simplesmente Plataforma tanto o site{" "}
              <a
                href="https://www.clickrifas.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                clickrifas.com
              </a>{" "}
              e seus subdomínios, como quaisquer outros sites, interfaces,
              aplicativos e outros meios nos quais a Click Rifas disponibilize
              seus recursos. Os serviços relacionados aos recursos
              disponibilizados pela Click Rifas são coletivamente referidos como
              Serviços. Ao acessar ou usar a Plataforma, você concorda em se
              vincular a estes Termos e em cumprir as suas regras. Você deve ler
              estes Termos de forma atenta e cuidadosa, pois eles contêm
              informações importantes sobre seus direitos e obrigações relativos
              ao acesso ou uso dos recursos ou Serviços da Click Rifas.
            </p>

            <label className="fw-medium">VISÃO GERAL</label>
            <p>
              <span className="fw-semibold">A.</span> Estes Termos regulam o
              acesso ou uso de recursos contidos na Plataforma para todos os
              Usuários, em qualquer lugar do mundo.
            </p>

            <p>
              <span className="fw-semibold">B.</span> Estes Termos constituem um
              contrato vinculante entre você, como Usuário, e a Click Rifas, e
              regem seu acesso e uso: (a) do site {" "}
              <a
                href="https://www.clickrifas.com"
                target="_blank"
                rel="noopenner noreferrer"
              >
                www.clickrifas.com
              </a>{" "}
              e seus subdomínios; (b) de quaisquer outros sites, interface,
              aplicativos ou outros meios nos quais a Click Rifas disponibilize
              seus recursos, inclusive de nossos aplicativos de celular, tablet
              ou de outros dispositivos eletrônicos; e (c) de todos os Serviços
              relacionados aos recursos disponibilizados pela Click Rifas.
            </p>

            <p>
              <span className="fw-semibold">C.</span> A empresa que você
              contrata para usar a Plataforma e operacionalizar suas transações
              são realizadas em Reais (BRL) via pix e a empresa que processa a
              transação <span className="fw-bold">é a empresa Din Pay</span>. Ao
              aceitar estes termos, você concede à Click Rifas amplos poderes
              para realizar todas as atividades necessárias para manutenção da
              relação comercial estabelecida e o cumprimento destes Termos e
              demais Políticas
            </p>

            <p>
              <span className="fw-semibold">D.</span> Você declara que conhece e
              concorda com o seu conteúdo e com as demais Políticas da Click
              Rifas aplicáveis, inclusive com a Política de Privacidade. As
              Políticas da Click Rifas são parte integrante destes Termos e se
              incorporam a eles por referência, ainda que sejam apresentadas em
              textos separados. Em caso de conflito, as regras destes Termos
              devem prevalecer sobre as condições estabelecidas em outras
              Políticas da Click Rifas, exceto se houver previsões específicas
              estabelecendo que determinada Política prevalece. Estes Termos e
              suas atualizações também prevalecem sobre todas as propostas,
              entendimentos ou acordos anteriores, verbais ou escritos, que
              possam existir entre você e a Click Rifas
            </p>

            <p>
              <span className="fw-semibold">E.</span> Após você aceitar estes
              Termos, a Click Rifas concede a você automaticamente uma licença
              de uso não exclusiva da Plataforma. Os recursos contidos na
              Plataforma Click Rifas são licenciados no estado em que se
              encontram. Eles podem ser modificados, substituídos ou removidos
              da Plataforma pela Click Rifas a qualquer momento, sem aviso
              prévio.
            </p>

            <p>
              <span className="fw-semibold">F.</span> O respeito às condições
              destes Termos é essencial para o uso legítimo dos recursos
              disponibilizados na Plataforma. Se você usar a Plataforma de modo
              incompatível com estes Termos, a Click Rifas pode aplicar
              diferentes medidas, a qualquer tempo, com ou sem aviso prévio.
              Essas medidas podem incluir, sem prejuízo de outras, (a) a
              suspensão do acesso a um produto, (b) a suspensão do acesso à sua
              conta, (c) o cancelamento do seu cadastro ou (d) o encerramento da
              sua licença de uso da Plataforma. O modo de aplicação dessas e de
              outras medidas é detalhada ao longo destes Termos.
            </p>

            <p>
              <span className="fw-semibold">G.</span> Ao aceitar estes Termos,
              você autoriza que a Click Rifas reporte às autoridades competentes
              qualquer ato relacionado ao acesso ou uso da Plataforma que a
              Click Rifas considere ter indícios de irregularidades ou
              ilegalidades. O Usuário reportado não pode exigir que a Click
              Rifas pague qualquer tipo de indenização, por dano patrimonial ou
              extrapatrimonial, por consequência dessa comunicação.
            </p>

            <p>
              <span className="fw-semibold">H.</span> Em atenção a diretrizes
              expedidas por órgãos de alcance nacional. Click Rifas não
              transaciona ou opera com Usuários localizados em outros países, em
              atendimento às normas e boas práticas globais voltadas à prevenção
              da lavagem de dinheiro, atos fraudulentos e financiamento de
              atividades ilícitas
            </p>

            <p>
              <span className="fw-semibold">I.</span> A Click Rifas se preocupa
              com sua privacidade e tem o compromisso de preservá-la. O
              tratamento de dados pessoais relacionados ao seu acesso e uso da
              Plataforma está descrito na nossa Política de Privacidade.
            </p>

            <p>
              <span className="fw-semibold">J.</span> Todo serviço de
              processamento de pagamento relacionado ao seu uso da Plataforma ou
              realizado por meio da Plataforma é prestado a você conforme
              estabelecido na Política Geral de Pagamentos da Click Rifas.
            </p>

            <p>
              <span className="fw-semibold">K.</span> Ao usar os Serviços da
              Click Rifas com relação a qualquer Produto, você se responsabiliza
              por identificar, compreender e cumprir todas as leis, regras e
              regulamentações aplicáveis. Isso pode incluir, entre outras,
              normas de natureza cível, normas sobre propriedade intelectual,
              sobre o anúncio e conteúdo de seu Produto e sobre sua
              comercialização e compartilhamento perante os Compradores. As
              normas aplicáveis também podem exigir providências de natureza
              regulatória, como obtenção de licenças, permissões, além de
              inscrições em registros profissionais e o respeito a
              regulamentações emitidas por eles.
            </p>

            <p>
              <span className="fw-semibold">L.</span> A Click Rifas se reserva o
              direito de modificar estes Termos a qualquer tempo. Se estes
              Termos forem alterados, a Click Rifas publicará na Plataforma os
              Termos revisados e informará a data da última atualização no seu
              início (“versão atualizada em”). Também enviaremos a você uma
              notificação, dentro da Plataforma, sobre as alterações antes da
              data de início da sua vigência. Se você discordar da nova versão
              dos Termos, poderá encerrar este Contrato com efeito imediato. Se
              você não encerrar seu contrato antes da data de vigência da nova
              versão dos Termos, seu acesso contínuo ou seu uso da Plataforma
              significará que você aceita as alterações.
            </p>

            <p>
              <span className="fw-semibold">M.</span> Se a Click Rifas não
              exercer imediatamente algum direito que lhe seja cabível na forma
              da lei ou destes Termos, por qualquer motivo, ou mesmo se deixar
              de exercer algum direito em determinado caso, isso não significa
              que a Click Rifas estará renunciando ao exercício desses direitos.
              A Click Rifas pode exercer seus direitos a qualquer tempo, a seu
              critério, e, se a Click Rifas renunciar ao exercício de qualquer
              um de seus direitos individualmente, isso não significa que estará
              renunciando ao exercício de seus direitos como um todo.
            </p>

            <p>
              <span className="fw-semibold">N.</span> As expressões iniciadas
              por letras maiúsculas nestes Termos ou nas Políticas da Click
              Rifas têm significado próprio, para compreender em mais detalhes o
              conteúdo destes Termos e das Políticas da Click Rifas,
              recomendamos que você consulte o significado dessas expressões no
              Glossário.
            </p>

            <p>
              <span className="fw-semibold">O.</span> O uso de exemplos nestes
              Termos, bem como o uso de expressões como “inclusive”, “incluindo”
              e outros termos semelhantes, não pode ser interpretado de modo a
              limitar a abrangência das regras que os utilizam, tendo sempre
              função exemplificativa e, portanto, não exaustiva.
            </p>

            <p>
              <span className="fw-semibold">P.</span> O idioma oficial destes
              Termos é o portugues. Qualquer versão destes Termos em outro
              idioma é uma tradução fornecida por cortesia. Em caso de conflito,
              a versão em portugues deve prevalecer para todas as contratações.
            </p>

            <label className="fw-medium">
              1. OS SERVIÇOS PRESTADOS PELA CLICK RIFAS
            </label>

            <p>
              1.1.{" "}
              <span className="fw-semibold">
                Escopo dos Serviços da Click Rifas
              </span>
              : A Plataforma Click Rifas disponibiliza um conjunto de recursos
              online que possibilitam aos seus Usuários criar, divulgar ou
              comercializar Rifas Online. Quando os Usuários realizam uma
              transação sobre um Produto mediante a Plataforma, eles celebram um
              contrato diretamente um com o outro. A Click Rifas não é e não se
              torna parte interveniente ou garantidora de qualquer relação entre
              os Usuários.
            </p>

            <p>
              1.2.{" "}
              <span className="fw-semibold">
                Relação entre os Usuários com a Click Rifas
              </span>
              : Ao utilizar a Plataforma como Criador, Coprodutor, Afiliado ou
              Comprador, você é um contratante dos serviços da Click Rifas, e
              não se torna empregado, colaborador, representante, agente, sócio,
              associado ou parceiro da Click Rifas. Você não pode se apresentar
              como se tivesse vínculos desses tipos com a Click Rifas (por
              exemplo, você não pode se descrever nas redes sociais como
              “vendedor da Click Rifas” ou “agente da Click Rifas”). Você deve
              atuar exclusivamente em seu próprio nome e em seu próprio
              benefício, e não pode atuar em nome ou em benefício da Click
              Rifas. Você tem liberdade de atuação e discricionariedade para
              divulgar e comercializar os Produtos dos quais você seja Criador
              (individualmente ou em conjunto com Coprodutor) ou Afiliado,
              respeitadas as normas legais, as regras destes Termos e a Política
              de Uso Responsável da Click Rifas, bem como para desempenhar suas
              atividades comerciais e estabelecer suas relações de trabalho como
              achar adequado.
            </p>

            <p>
              1.3.{" "}
              <span className="fw-semibold">Titularidade dos Produtos</span>: A
              condição de titular e fornecedor dos recursos disponibilizados
              pela Plataforma não significa que a Click Rifas cria, elabora,
              controla, endossa ou fornece qualquer Produto ou serviço. Os
              Criadores e Coprodutores são integralmente responsáveis pelos
              Produtos e pelas informações inerentes a eles, inclusive suas
              regras de oferta e uso, de acordo com o negociado entre as Partes
              e desde que respeitada a integralidade dos Termos. A Click Rifas
              não se responsabiliza por quaisquer disputas ou danos, de qualquer
              natureza, que possam surgir do relacionamento entre seus Usuários,
              ou deles com terceiros. Em especial, a Click Rifas não é
              responsável por qualquer pretensão que você possa ter por confiar
              em uma informação fornecida por um Criador ou Coprodutor, por um
              Afiliado ou veiculada em um Produto.
            </p>

            <p>
              1.4.{" "}
              <span className="fw-semibold">
                Inexistência de Promessas de Desempenho, Ganho ou Resultados
                decorrentes do Uso da Plataforma
              </span>
              : A Click Rifas não promete nem garante que você atingirá qualquer
              desempenho, ganho ou resultado com o uso da Plataforma ou com a
              aquisição de qualquer coisa. Além disso, nenhum Criador,
              Coprodutor ou Afiliado pode prometer desempenho, ganho ou
              resultado inalcançável de qualquer natureza, seja decorrente do
              uso da Plataforma, de sua condição de Usuário, ou do uso ou
              comercialização dos Produtos. Assim, por exemplo, nenhum Criador,
              Coprodutor ou Afiliado pode prometer que o uso da Plataforma
              proporciona um ganho ao Usuário, nem anunciar que o uso de um
              Produto garante benefícios ou aumento de chances ao Comprador.
            </p>

            <p>
              1.5.{" "}
              <span className="fw-semibold">
                Conformidade dos Produtos com as Políticas da Click Rifas
              </span>
              : A Click Rifas disponibiliza um espaço e recursos para quem
              deseja criar, divulgar ou comercializar uma Rifa Online de forma
              simples e prática, possibilitando que as pessoas vivam de suas
              paixões, realizem os seus sonhos e de outras pessoas. Por isso,
              qualquer Criador ou Coprodutor pode usar a Plataforma para criar,
              divulgar ou comercializar suas Rifas. Em linha com a legislação
              aplicável, a Click Rifas não se obriga a fazer controle prévio de
              conteúdo (editorial ou de qualquer outra natureza) das Rifas, nem
              a fazer curadoria de qualquer tipo dos Produtos. A Click Rifas
              também não se obriga a fazer controle prévio da sua base de
              Usuários.
            </p>

            <p>
              1.6.{" "}
              <span className="fw-semibold">
                Processos de Verificação conduzidos pela Click Rifas
              </span>
              : A Click Rifas leva a integridade da comunidade que usa a
              Plataforma muito a sério e, por isso, se reserva o direito de
              realizar verificações antes ou depois do cadastro dos Usuários, da
              oferta de um Produto ou da inserção de algum conteúdo na
              Plataforma, bem como o direito de exigir informações adicionais ou
              até mesmo mudanças em Rifas já cadastrados na Plataforma.
              Quaisquer referências a um Usuário ou Rifas sendo “analisado” (ou
              linguagem similar) apenas indica que o Usuário ou Rifa se submete
              a um processo de verificação cadastral, e nada mais. Nenhuma
              dessas descrições significa endosso, certificação ou garantia da
              Click Rifas sobre a qualidade da Rifa. Essas descrições também não
              representam qualquer atestado de que o Usuário ou a Rifa estão
              adequados ao disposto na legislação aplicável, nestes Termos ou
              nas Políticas da Click Rifas. Lembramos que os Usuários são os
              responsáveis por cumprir todas as regras aplicáveis às Rifas
              cadastradas e à sua comercialização e compartilhamento, e não a
              Click Rifas.
            </p>

            <p>
              <mark style={{ backgroundColor: "#FFFF00" }}>
                1.7.{" "}
                <span className="fw-semibold">
                  Marketplace e Anúncio através de Serviços de Terceiros
                </span>
              </mark>
              : Para ampliar a exposição dos Produtos, a Click Rifas pode
              incluí-los em seu marketplace na Plataforma, de acordo com
              critérios estabelecidos pela própria Click Rifas. Ao inserir um
              Produto na Plataforma, o Criador e o Coprodutor autorizam sua
              exposição. A Click Rifas também pode mencionar ou promover os
              Produtos em suas comunicações, interna ou externa, bem como em
              outros sites, em aplicativos, em publicidade online e offline, por
              si ou por terceiros, inclusive por meio de Serviços de Terceiros.
              Se qualquer dessas hipóteses ocorrer, a Click Rifas preserva sua
              independência em relação aos Produtos e aos Criadores e
              Coprodutores, e essa divulgação não significa que a Click Rifas
              endossa ou concorda com o conteúdo dos Produtos. Além disso, os
              Criadores e Coprodutores que tiverem seus Produtos divulgados não
              fazem jus a qualquer benefício, remuneração ou indenização por
              essa divulgação ou fim da exposição no marketplace.
            </p>

            <p>
              1.8.{" "}
              <span className="fw-semibold">
                Acesso à Internet e à Plataforma
              </span>
              : Devido à natureza da Internet, a Click Rifas não pode garantir
              que a Plataforma fique disponível e acessível ininterruptamente.
              Além disso, a Click Rifas pode restringir a disponibilidade da
              Plataforma ou de certas áreas ou recursos a ela relacionados, se
              necessário, considerando os limites de capacidade, a segurança ou
              a integridade de seus servidores, bem como para realizar medidas
              de manutenção ou aprimoramento dos seus serviços. A Click Rifas
              não pode ser responsabilizada pelo Usuário ou por qualquer
              terceiro em função do impedimento ou alteração na forma de acesso
              à Plataforma e aos Serviços. A Click Rifas pode melhorar e alterar
              a Plataforma a qualquer tempo, seja para modificar, substituir ou
              remover Serviços existentes, ou para adicionar Serviços novos.
            </p>

            <p>
              1.9.{" "}
              <span className="fw-semibold">
                Prestação de Serviços de Terceiros
              </span>
              : A Plataforma pode conter links para sites ou recursos de
              terceiros. Esses Serviços de Terceiros não integram o escopo dos
              Serviços da Click Rifas e não fazem parte da Plataforma. Por isso,
              eles estão sujeitos a termos e condições diferentes, inclusive com
              relação às práticas de privacidade. A Click Rifas não é
              responsável pela disponibilidade ou pela precisão dos Serviços de
              Terceiros, tampouco pelo seu conteúdo, pelos produtos ou pelos
              serviços disponíveis neles. Os links para esses Serviços de
              Terceiros não representam endosso ou concordância da Click Rifas a
              seu respeito.
            </p>

            <p>
              1.10.{" "}
              <span className="fw-semibold">
                Atendimento ao Cliente e Solução de Controvérsias entre os
                Usuários
              </span>
              : A Click Rifas dispõe de mecanismos de atendimento aos Usuários
              para facilitar a solução de problemas relativos aos Serviços da
              Click Rifas. A Click Rifas também dispõe de mecanismos destinados
              a facilitar a comunicação para solução de problemas e eventuais
              conflitos entre você e outro Usuário, funcionando como canal de
              diálogo em busca de solução consensual entre os Usuários. Nos
              casos em que a Click Rifas realizar atendimento nos contratos
              gerenciados pela Click Rifas BV, e dentro de suas limitações, esta
              o realizará em nome dos Criadores, como agente comercial.
            </p>

            <label className="fw-medium">
              2. ELEGIBILIDADE, CADASTRO E ACESSO À PLATAFORMA
            </label>
            <p>
              2.1.{" "}
              <span className="fw-semibold">
                Elegibilidade dos Criadores, Coprodutores, Colaboradores ou
                Afiliados
              </span>
              : Para registrar uma conta na Plataforma como Criador, Coprodutor,
              Colaborador ou Afiliado, é necessário que você tenha pelo menos 18
              anos de idade, ou seja emancipado nos moldes da legislação
              aplicável. Ao aceitar estes Termos, você declara ser plenamente
              capaz para exercer todos os atos da vida civil. Se o cadastro na
              Click Rifas for efetuado em nome de pessoa jurídica, você declara
              que tem poder e todas as autorizações necessárias para vinculá-la
              regularmente, inclusive para conceder à Click Rifas todas as
              permissões e licenças referidas nestes Termos ou nas Políticas da
              Click Rifas.
            </p>

            <p>
              2.2.{" "}
              <span className="fw-semibold">Elegibilidade dos Compradores</span>
              : A idade mínima para Compradores se cadastrarem na Plataforma é
              de 13 anos. Adolescentes entre 13 e 18 anos precisam estar
              regularmente autorizados pelos pais ou responsáveis para tanto. O
              cadastro na Plataforma e a aceitação destes Termos pressupõem que
              essa autorização foi concedida. A Click Rifas deve cancelar o
              cadastro de Usuário menor de 18 anos a pedido dos pais ou
              responsáveis, mas isso não gera direito a qualquer indenização ou
              reembolso em razão dos Produtos adquiridos pelo menor durante a
              vigência de seu cadastro.
            </p>

            <p>
              2.3. <span className="fw-semibold">Usuários Restritos</span>: A
              Click Rifas pode recusar ou cancelar o cadastro de determinado
              Usuário se tomar conhecimento, por si ou por denúncia fundamentada
              de terceiro, da existência de condenação, mediante sentença
              transitada em julgado, por (a) crimes hediondos, ou equiparados a
              crimes hediondos; (b) envolvimento em organizações criminosas,
              lavagem de dinheiro, atos terroristas ou tráfico internacional de
              pessoas; ou (c) crimes cometidos por meios eletrônicos ou
              mecanismos cibernéticos.
            </p>

            <p>
              2.4.{" "}
              <span className="fw-semibold">
                Suspensão ou Encerramento da sua Conta
              </span>
              : Se a Click Rifas limitar seu acesso ou uso da Plataforma,
              suspender sua conta ou encerrar este Contrato, você não poderá
              registrar uma nova conta na Plataforma, nem acessar ou usar a
              Plataforma através da conta de outro Usuário sem autorização
              prévia da Click Rifas, e desde que os motivos que ensejaram a
              suspensão ou cancelamento não subsistam.
            </p>

            <p>
              2.5. <span className="fw-semibold">Cadastro</span>: Para usar a
              Plataforma ou cadastrar um Produto, você se obriga a preencher
              adequadamente, e com informações corretas, todos os dados
              solicitados pela Click Rifas no cadastramento, cientes de que
              eventuais equívocos poderão trazer impactos ao Usuário, inclusive
              fiscais. A Click Rifas pode usar quaisquer meios legalmente
              admitidos para lhe identificar, assim como pode requerer de você a
              qualquer momento dados adicionais para complementar aqueles já
              fornecidos. Se você enviar informações inverídicas, incorretas ou
              incompletas, os seus cadastros podem ser suspensos ou cancelados.
              A Click Rifas pode recusar seus cadastros ou cancelá-los se
              entender que há indícios de que você está usando ou tende a
              utilizar a Plataforma em desacordo com estes Termos.
            </p>

            <p>
              2.6.{" "}
              <span className="fw-semibold">
                Exatidão e Veracidade dos Dados
              </span>
              : Você é o único responsável por cadastrar dados verdadeiros,
              exatos e atualizados, e responde pelas consequências dos dados ou
              informações inverídicos, incompletos ou incorretos que fornecer no
              cadastro ou depois dele. Confira sempre as informações fornecidas
              à Plataforma antes de concluir o seu cadastro.
            </p>

            <p>
              2.7.{" "}
              <span className="fw-semibold">
                Senha de Acesso e Atividades da Conta
              </span>
              : Você é o único responsável por seu login, por sua senha, e por
              qualquer atividade conduzida por meio de sua conta na Plataforma.
              A Click Rifas não é responsável por quaisquer danos, patrimoniais
              ou extrapatrimoniais, resultantes do uso indevido da sua conta por
              terceiros. É imprescindível que você mantenha a confidencialidade
              e a segurança das suas credenciais de acesso à sua conta na Click
              Rifas, que são pessoais e intransferíveis. Não divulgue ou de
              qualquer maneira compartilhe essas credenciais a terceiros. Nenhum
              empregado, colaborador, representante, agente ou qualquer pessoa
              vinculada direta ou indiretamente à Click Rifas está autorizada a
              solicitar que você compartilhe sua senha de acesso. Você também
              não deve compartilhar suas credenciais de acesso com Criadores ou
              Afiliados, pois as atividades que esses Usuários desempenham não
              dependem do compartilhamento desse tipo de informação. Você deve
              notificar a Click Rifas imediatamente se você tomar conhecimento
              ou suspeitar que suas credenciais de acesso foram extraviadas,
              furtadas, apropriadas indevidamente por terceiros, tiveram sua
              confidencialidade comprometida ou foram de qualquer forma
              utilizadas sem sua autorização.
            </p>

            <p>
              2.8. <span className="fw-semibold">Dados Bancários</span>: Após
              realizar a primeira venda, os Criadores, Coprodutores ou Afiliados
              devem fornecer dados bancários, indicando conta corrente de sua
              titularidade. Se o cadastro estiver no Brasil, a conta bancária
              precisa estar no mesmo domicílio, tanto nos casos de pessoa
              física, quanto jurídica. Nos casos de Usuários com contas em
              outros países, se pessoa física, aplicam-se as mesmas regras do
              Brasil, se pessoa jurídica, será permitido o cadastro de contas
              bancárias de outros países, desde que de mesma titularidade. Estes
              Usuários são responsáveis por manter as referidas informações
              atualizadas na Plataforma. O cadastro ou o repasse de valores
              devidos aos Criadores, Coprodutores ou Afiliados para conta
              bancária de terceiros não é permitido em nenhuma circunstância e a
              nenhum pretexto. Também é proibido o cadastro ou repasse de
              valores para conta bancária de mesma titularidade, mas situada
              fora do domicílio do cadastro do Criador, Coprodutor ou Afiliado.
            </p>

            <p>
              2.9. <span className="fw-semibold">Tratamento dos Dados</span>: A
              Política de Privacidade da Click Rifas rege o tratamento dos dados
              pessoais que você fornece à Click Rifas durante o uso e o acesso
              da Plataforma.
            </p>

            <label className="fw-medium">
              3. SEUS PRINCIPAIS COMPROMISSOS COM A CLICK RIFAS E COM OS DEMAIS
              USUÁRIOS
            </label>
            <p>
              3.1.{" "}
              <span className="fw-semibold">
                Obrigações Gerais dos Usuários da Plataforma
              </span>
              : Você deve acessar a Plataforma ou usar os Serviços da Click
              Rifas apenas para fins lícitos. Você deve preservar a reputação da
              Click Rifas e evitar qualquer prática realizada por você (ou por
              terceiros relacionados a você) que possa, direta ou indiretamente,
              desabonar a Click Rifas, seus Serviços, seus empregados,
              colaboradores, representantes, agentes, sócios ou parceiros.
            </p>

            <p>
              3.2.{" "}
              <span className="fw-semibold">
                Direitos de Propriedade Intelectual da Click Rifas
              </span>
              : As informações contidas na Plataforma, bem como as marcas,
              patentes, desenhos industriais, nomes empresariais, nomes de
              domínio, programas, bancos de dados, redes, arquivos, obras
              autorais, mídias em geral (áudio, texto, vídeo etc.) e qualquer
              outra propriedade intelectual relacionada aos Serviços da Click
              Rifas ou contida na Plataforma são de titularidade exclusiva da
              Click Rifas. Esses elementos são protegidos pelas leis e por se
              tratar de propriedade intelectual. É proibido copiar, distribuir,
              usar ou publicar total ou parcialmente qualquer material, qualquer
              item da Plataforma ou qualquer Produto ofertado na Plataforma sem
              prévia autorização por escrito da Click Rifas.
            </p>

            <p>
              3.3.{" "}
              <span className="fw-semibold">
                Relacionamento dos Usuários entre si e com Terceiros
              </span>
              : Você se compromete a manter um relacionamento saudável e
              harmonioso com os demais Usuários e com terceiros a respeito do
              uso da Plataforma. Você não pode agredir, caluniar, injuriar ou
              difamar outros Usuários ou terceiros, inclusive os empregados,
              colaboradores, representantes, agentes, sócios ou parceiros da
              Click Rifas com os quais você tenha contato. Se a Click Rifas
              constatar que você agrediu, assediou, discriminou ou de qualquer
              outra forma lesionou direitos dessas pessoas, da Click Rifas ou de
              outros Usuários, a Click Rifas pode, a seu critério, tomar uma
              série de medidas previstas nestes Termos, que podem incluir a
              suspensão da sua licença de uso da Plataforma e o encerramento da
              prestação dos Serviços para você, excluindo-o da Plataforma, além
              de buscar concomitantemente a reparação de quaisquer danos,
              patrimoniais ou extrapatrimoniais, que você causar.
            </p>

            <p>
              3.4.{" "}
              <span className="fw-semibold">Indenizações à Click Rifas</span>:
              Ao aceitar estes Termos, você se obriga a manter a Click Rifas
              indene e a reparar quaisquer danos que você causar à Click Rifas
              por ação ou omissão que seja imputável a você. Essa obrigação de
              evitar e reparar danos inclui os patrimoniais e extrapatrimoniais,
              além de todas as despesas incorridas pela Click Rifas em
              decorrência de condutas imputáveis a você, como despesas com
              obrigações tributárias, ações judiciais, acordos extrajudiciais,
              honorários de advogados e peritos, multas e juros, decorrentes dos
              Produtos comercializados. Você também se obriga a manter indene e
              a reparar os danos que você causar aos sócios da Click Rifas, aos
              seus controladores, às suas sociedades controladas ou coligadas,
              aos seus administradores, diretores, gerentes, empregados,
              agentes, colaboradores, representantes e procuradores.
            </p>

            <p>
              3.5.{" "}
              <span className="fw-semibold">
                Uso Adequado da Plataforma e dos Serviços da Click Rifas
              </span>
              : A Click Rifas oferece um ambiente seguro para que qualquer
              pessoa possa criar, divulgar, comercializar ou comprar um Produto
              e, assim, iniciar suas rifas e adquirir novas habilidades. Desse
              modo, e sem prejuízo de outras regras previstas nestes Termos ou
              nas Políticas da Click Rifas: (a) você não pode violar ou tentar
              violar quaisquer medidas de segurança da Plataforma, nem tirar
              proveito de eventual inconsistência de sistemas da Click Rifas;
              (b) você não pode alterar os dados de qualquer Produto depois que
              a Click Rifas concluir o processo de verificação cadastral,
              inclusive seu título, descritivo, condições de compra e conteúdo
              do Produto digital ou físico; (c) você não pode induzir terceiros
              a acreditarem, por ação ou omissão sua, dentro ou fora da
              Plataforma, que você é empregado, colaborador, prestador de
              serviço, representante ou que mantém com a Click Rifas qualquer
              outro vínculo além de Usuário; (d) você não pode manipular preços
              de Produtos; (e) você não pode adotar quaisquer práticas de
              divulgação, captação de informação ou publicidade que sejam
              indesejadas, massivas, repetitivas, fora de contexto, enganosas,
              inapropriadas, caluniosas ou ilegais, para promover qualquer
              Produto cadastrado na Plataforma, como spam, flooding, adware,
              malware e outras técnicas nocivas; (f) você não pode declarar nem
              sugerir que a Plataforma é um meio fácil e/ou rápido de obter
              rendimentos, ou que não requer trabalho ou esforço; (g) você não
              pode comprar qualquer Produto com seu próprio link de Afiliado;
              (h) você deve utilizar a Plataforma com respeito, sempre
              ponderando os Conteúdos Gerados por Usuários que inserir e/ou os
              Produtos que vender através da Plataforma; (i) você não pode usar
              link de Afiliados de seu próprio Produto em sua página de produto;
              (j) você não pode substituir o link de Afiliado constante de uma
              página de produto em benefício próprio ou de terceiros; (k) você
              não pode utilizar qualquer sistema para o envio de requisições de
              acesso e utilização dos Serviços que supere, em um dado período de
              tempo, o que seria normalmente possível responder, levando ao
              impedimento de acesso, deteriorando ou de qualquer forma alterando
              a experiência de utilização da Plataforma e de seus conteúdos; e
              (l) você se obriga a não utilizar qualquer sistema automatizado,
              inclusive robôs, spiders, scripts ou offline readers, que acessem
              os Serviços e venham a utilizá-los de forma contrária ao previsto
              nestes Termos ou na legislação em vigor.
            </p>

            <label className="fw-medium">
              4. O USO DOS RECURSOS E OS CONTEÚDOS QUE PODEM SER COMERCIALIZADOS
              MEDIANTE A PLATAFORMA
            </label>
            <p>
              4.1.{" "}
              <span className="fw-semibold">Os Recursos da Plataforma</span>: A
              Click Rifas oferece uma plataforma totalmente integrada e com
              soluções para escalar o seu negócio, incluindo o pagamento
              nacional e seguro das transações entre os Usuários. O objetivo é
              que o Criador consiga focar em desenvolver e/ou ofertar Produtos
              de qualidade e criar relacionamentos mais próximos com seus
              Compradores.
            </p>

            <p>
              4.2.{" "}
              <span className="fw-semibold">
                Conteúdo do Criador e dos Coprodutores
              </span>
              : Ao usar qualquer recurso da Plataforma para criar, divulgar ou
              comercializar um Produto, o Criador, por si e em razão de seu
              relacionamento com outros Coprodutores (se aplicável), declara ser
              o titular exclusivo de todos os direitos de propriedade
              intelectual relativos ao Produto e ao seu conteúdo, ou estar
              regularmente autorizado pelos titulares desses direitos para
              fazê-lo. O Criador também garante que o Produto não viola
              quaisquer direitos de propriedade intelectual de terceiros,
              inclusive de imagem ou direitos autorais, sendo o Produto obra
              original, ou tendo o Criador e o Coprodutor recebido as licenças
              ou cessões de direitos pertinentes dos titulares de direitos sobre
              o conteúdo integrante do Produto. Além disso, o Criador e o
              Coprodutor devem ter todos os direitos para conceder à Click Rifas
              as licenças necessárias para a Click Rifas desempenhar suas
              atividades relativas ao uso da Plataforma com relação ao Produto,
              na forma destes Termos e das Políticas da Click Rifas.
            </p>

            <p>
              4.3.{" "}
              <span className="fw-semibold">
                Exclusividade dos Produtos criados, divulgados ou
                comercializados mediante a Plataforma
              </span>
              : Ao usar qualquer recurso da Plataforma para criar, divulgar ou
              comercializar um Produto, o Criador e o Coprodutor, quando
              aplicável, declaram ter o direito de comercializar o Produto em
              caráter de exclusividade e assumem a obrigação de comercializá-lo
              exclusivamente pela Plataforma. O Criador e o Coprodutor também
              não podem autorizar terceiros a explorarem qualquer Produto
              concorrentemente, nem deixar de tomar medidas para impedir que
              terceiros os explorem em concorrência com a Click Rifas.
            </p>

            <p>
              4.4.{" "}
              <span className="fw-semibold">Uso Responsável da Plataforma</span>
              : O propósito da Click Rifas é possibilitar que as pessoas
              realizem rifas, assim. Para atingir esse objetivo, a Click Rifas
              adota alguns valores e princípios, que devem ser observados por
              todos seus Usuários: o respeito ao próximo, aos Usuários e às leis
              locais é um deles, assim como a proteção à liberdade de expressão
              e a neutralidade e isonomia das redes de Internet. Por isso, a
              Click Rifas não aceita conteúdos que violem essas diretrizes. Ao
              aceitar estes Termos, você se obriga a respeitar a Política de Uso
              Responsável da Click Rifas, que exemplifica de maneira não
              exaustiva os Produtos que a Click Rifas não deseja na Plataforma.
              Em termos gerais, você não pode usar a Plataforma para criar,
              divulgar ou comercializar Produtos e/ou inserir Conteúdos Gerados
              por Usuários (a) que violem a legislação aplicável ou direitos de
              terceiros; (b) que incitem violência, ódio, discriminação, ou que
              possam causar prejuízo aos Usuários ou à Click Rifas; (c) que
              prejudiquem a experiência dos Usuários na Plataforma; ou (d) que
              estejam em desacordo com o modelo de negócios da Click Rifas ou
              que sejam contrários aos valores da empresa. Veja a Política de
              Uso Responsável da Click Rifas para saber mais.
            </p>

            <p>
              4.5.{" "}
              <span className="fw-semibold">
                Avaliações e Demais Conteúdos Gerados por Usuários
              </span>
              : No Click Rifas, o Usuário reconhece que é o único responsável
              por todo o conteúdo publicado por ele, isentando a Click Rifas de
              qualquer responsabilidade relacionada a eventuais denúncias,
              reclamações, prejuízos, perdas e danos causados a Criadores,
              Coprodutores, Afiliados, Compradores e/ou terceiros. O Usuário se
              compromete a não infringir direitos de terceiro e estar em
              conformidade com a Política de Uso Responsável da Click Rifas, a
              qual se aplica a este item em sua integralidade. A Click Rifas se
              reserva o direito de, a qualquer tempo, aplicar quaisquer das
              medidas das Seções 10 e 11 destes Termos em caso de descumprimento
              do presente item.
            </p>

            <p>
              4.6.{" "}
              <span className="fw-semibold">
                Conteúdo Disponibilizado ao Criador
              </span>
              : A Click Rifas pode cessar de forma temporária ou definitiva o
              acesso do Criador, inclusive por bloqueio de acesso ao Produto ou
              bloqueio de Usuário, se for verificada a inobservância destes
              Termos e/ou demais Políticas da Click Rifas, bem como se houver
              suspeita de fraude. Uma fraude pode ser caracterizada pelo
              fornecimento ou compartilhamento de senha, assim como por outras
              condutas, como o download, transmissão, retransmissão ou
              armazenamento não autorizado do Produto.
            </p>

            <label className="fw-medium">
              5. AS PRINCIPAIS REGRAS APLICÁVEIS AOS CRIADORES
            </label>
            <p>
              5.1. <span className="fw-semibold">Exclusividade</span>: Todos os
              Produtos devem ser comercializados na Plataforma em caráter
              exclusivo. É vedado ao Criador e Coprodutor exibir, divulgar ou
              comercializar qualquer Produto, total ou parcialmente, por outras
              plataformas, meios ou suportes, online ou offline, a qualquer
              título, de maneira onerosa ou gratuita.
            </p>

            <p>
              5.2.{" "}
              <span className="fw-semibold">
                Proibição de Uso de Marca ou Associação Inadequada da Click
                Rifas
              </span>
              : Você não pode usar qualquer marca, logo ou nome comercial da
              Click Rifas para divulgar qualquer Produto, e nem indicar direta
              ou indiretamente que a Click Rifas se associou, aprovou ou
              certificou o Produto. Você deve usar e acessar a Plataforma apenas
              nos limites necessários para usar os Serviços da Click Rifas na
              criação, divulgação, comercialização ou utilização do Produto.
            </p>

            <p>
              5.3.{" "}
              <span className="fw-semibold">
                Principais Licenças que o Criador e Coprodutor concedem à Click
                Rifas ao cadastrar um produto digital
              </span>
              : O Criador e Coprodutor, este último nos casos de coprodução em
              modelo de parceria de negócio, serão sempre os titulares de todos
              os direitos e obrigações relacionados ao Produto criado, divulgado
              ou comercializado na Plataforma. Contudo, ao cadastrar um Produto
              e enquanto forem usuários desta, o Criador e o Coprodutor
              automaticamente concedem à Click Rifas autorização para
              comercialização e disponibilização do produto perante os
              Compradores, licença de uso de todos os direitos de propriedade
              intelectual relativos ao Produto, inclusive os direitos autorais,
              patrimoniais, de modo sublicenciável. A integração dos Serviços da
              Click Rifas a qualquer Produto não significa que o Produto ou os
              direitos de propriedade intelectual a ele associados passam a ser
              de titularidade da Click Rifas. Essa licença é concedida de forma
              não exclusiva, sendo válida em todo o território nacional, para
              todos os fins e efeitos legais, e para todas as modalidades de
              exploração, com o objetivo de a Click Rifas usar, reproduzir,
              processar, adaptar, modificar, traduzir, incluir legendas,
              publicar, transmitir, exibir ou distribuir o Produto em qualquer
              mídia ou suporte, por qualquer meio de distribuição, conhecido ou
              a ser desenvolvido, dentro e fora da Plataforma.
            </p>

            <p>
              5.3.1. Na hipótese de encerramento deste Contrato, por qualquer
              dos motivos previstos na Seção 11 deste instrumento, a Click Rifas
              se compromete a não mais usar, reproduzir, processar, adaptar,
              modificar, traduzir, incluir legendas, publicar, transmitir,
              exibir, distribuir ou comercializar o Produto em sua Plataforma
              para a realização de novas vendas.
            </p>

            <p>
              5.4.{" "}
              <span className="fw-semibold">
                Proteção de Direitos relativos ao Produto
              </span>
              : O objetivo da Click Rifas é criar um espaço em que o Criador e o
              Coprodutor possam criar rifas e gerar renda com isso. Por isso, é
              importante para a Click Rifas que os direitos dos Criadores e
              Coprodutores sobre os Produtos que são cadastrados na Plataforma
              sejam respeitados. Para tanto, ao cadastrar qualquer Produto na
              Plataforma, o Criador e o Coprodutor cedem automaticamente à Click
              Rifas o direito de também tomar quaisquer medidas, judiciais ou
              extrajudiciais, para a Click Rifas mover as pretensões que
              entender aplicáveis para proteger os direitos de propriedade
              intelectual relativos ao Produto, patrimoniais ou morais, bem como
              cede os direitos de exigir e receber qualquer indenização
              decorrente da sua violação. Isso inclui todos os poderes
              necessários para que a Click Rifas tome as medidas que entender
              cabíveis, aos seus custos como, por exemplo, mover ação judicial,
              ingressar como parte em ação judicial movida por terceiros,
              realizar acordos judiciais ou extrajudiciais, interpor recursos,
              dar e receber quitação, bem como todos os demais poderes
              habitualmente exigíveis para o exercício e a proteção de direitos
              em juízo.
            </p>

            <p>
              5.5.{" "}
              <span className="fw-semibold">Dados Pessoais dos Usuários</span>:
              O Criador deve tratar os dados pessoais dos Usuários aos quais
              tiver acesso em decorrência dos Serviços da Click Rifas em total
              respeito às leis aplicáveis, especialmente ao Termo de Tratamento
              de Dados Pessoais.
            </p>

            <p>
              5.6.{" "}
              <span className="fw-semibold">
                Transparência e Dever de Informação
              </span>
              : Além das informações de cadastro, dos dados bancários e dos
              demais dados da conta, o Criador e o Coprodutor devem fornecer à
              Click Rifas e a terceiros, Usuários ou não, todas as informações
              razoavelmente esperadas relativas aos Produtos. Essas informações
              devem ser prestadas de maneira completa, inequívoca, objetiva,
              transparente e atualizada, dentro e fora da Plataforma,
              notadamente quanto (a) ao assunto, composição, descrição,
              objetivo, características, qualidade e quantidade do Produto; (b)
              ao preço e forma de pagamento; (c) às regras, formas, prazos,
              validade e períodos e meios de entrega, acesso e devolução; (d)
              regras em caso de extravio de Produtos; (e) às atualizações e
              versões, se aplicável; (f) aos requisitos obrigatórios e
              certificação, se aplicável; (g) aos deveres que devem ser
              cumpridos pelos Usuários e limites aos seus direitos; (h) a
              qualquer outra informação relevante ou decorrente de obrigação
              legal sobre o aproveitamento e a comercialização do Produto.
            </p>

            <p>
              5.6.1. <span className="fw-semibold">Disclaimers</span>: Além de
              eventuais advertências exigíveis em razão de leis, regulamentos ou
              convenções que disponham sobre a publicidade e anúncios de
              produtos, a Click Rifas pode sugerir ou requerer a inserção de
              advertências para determinadas categorias de produtos, ou para
              alguns produtos específicos, no intuito de garantir maior
              transparência sobre os riscos e as expectativas de entrega de
              Produtos cadastrados na Plataforma.
            </p>

            <p>
              5.7. <span className="fw-semibold">Produtos Duplicados</span>: O
              Criador e o Coprodutor não podem cadastrar o mesmo Produto em
              duplicidade, comercializando-o mediante dois ou mais cadastros
              diferentes, sem que seus respectivos anúncios ofereçam contas de
              pagamentos distintas entre si.
            </p>

            <p>
              5.8.{" "}
              <span className="fw-semibold">
                Responsabilidade por Terceiros Relacionados
              </span>
              : Os Criadores e Coprodutores são integral e solidariamente
              responsáveis por ações ou omissões praticadas por Terceiros
              Relacionados. São considerados Terceiros Relacionados quaisquer
              pessoas, naturais ou jurídicas, que estejam direta ou
              indiretamente relacionadas a um Produto, como especialistas,
              atores, personagens ou garotos-propaganda. Ao cadastrar um Produto
              na Plataforma, os Criadores e Coprodutores garantem que os
              Terceiros Relacionados conhecem, concordam e se obrigam a cumprir
              estes Termos e as Políticas da Click Rifas, pois eles se aplicam a
              essas pessoas. Os Criadores e Coprodutores se responsabilizam
              integralmente por obter e manter eficazes, durante todo o período
              de vida do Produto na Plataforma, os direitos para a exploração da
              imagem e dos demais direitos de personalidade dos Terceiros
              Relacionados, na extensão necessária para a comercialização do
              Produto. Na hipótese de um Terceiro Relacionado ser um menor de 18
              anos, o Criador e Coprodutores devem garantir que têm as
              respectivas autorizações dos responsáveis e órgãos competentes
              para emitir permissões nesse sentido. A participação de menores de
              18 anos em textos, imagens ou sons contidos nos Produtos deve ser
              muito restrita, somente quando essencial para a apresentação do
              tema proposto, e o Criador e Coprodutores devem cumprir e se
              responsabilizar pelo cumprimento de todas as normas aplicáveis
              sobre o uso de trabalho infantil, além do estabelecido nestes
              Termos. A Click Rifas pode aplicar medidas perante qualquer
              Usuário ou Produto, inclusive removê-los da Plataforma, com base
              em ações ou omissões de Terceiros Relacionados que violem estes
              Termos.
            </p>

            <p>
              5.9. <span className="fw-semibold">Os Coprodutores</span>:
              Qualquer Criador pode cadastrar Usuários da Plataforma como
              Coprodutor de determinado Produto, desde que esses sejam
              plenamente capazes, nos termos da legislação aplicável,
              autorizando-o a praticar atos relativos a determinado Produto sem
              que seja necessário o compartilhamento das credenciais de acesso
              do Criador à Plataforma. Ao efetuar esse cadastro, cabe ao Criador
              definir o conjunto de atos facultados a cada Coprodutor, bem como
              o modelo de coprodução aplicável e a comissão ou os percentuais a
              que cada Coprodutor faz jus pela venda de determinado Produto.
              Para a realização do cadastro na Plataforma, o Coprodutor concede
              ao respectivo Criador todos os poderes necessários para agir em
              seu nome e praticar todos os atos relacionados perante a Click
              Rifas, demais Usuários e terceiros. O Coprodutor declara-se ciente
              e concorda que: (a) todas as obrigações previstas nestes Termos
              para os Criadores também lhe serão aplicáveis, na qualidade de
              Usuário; e (ii) salvo quando houver disposição em sentido
              contrário nestes Termos, os direitos concedidos aos Criadores não
              lhe serão estendidos. Os Criadores são integral e solidariamente
              responsáveis perante os Usuários, Terceiros e a Click Rifas, pelas
              atividades desempenhadas pelos Coprodutores relativas aos seus
              Produtos. Da mesma maneira, os Coprodutores são integral e
              solidariamente responsáveis perante os Usuários, terceiros e a
              Click Rifas pelas atividades desempenhadas pelos Criadores
              relativas aos Produtos dos quais são cadastrados como
              Coprodutores. Ao aceitar estes Termos, os Criadores isentam a
              Click Rifas de responsabilidade por quaisquer danos, patrimoniais
              ou extrapatrimoniais, decorrentes de ações ou omissões praticadas
              pelos Coprodutores, e os Coprodutores isentam a Click Rifas de
              responsabilidade por quaisquer danos, patrimoniais ou
              extrapatrimoniais, decorrentes de ações ou omissões praticadas
              pelos Criadores.
            </p>

            <p>
              5.9.1. <span className="fw-semibold">Tipos de Coprodução</span>:
              As coproduções podem ser realizadas em formato de Comissionamento
              ou Parceria de Negócio, cabendo exclusivamente ao Criador decidir
              qual será aplicável ao seu Produto, bem como escolher o Usuário
              com o qual compartilhará tal relação, nos termos abaixo:
            </p>

            <p>
              5.9.1.1. <span className="fw-semibold">Comissionamento</span>: na
              coprodução do tipo “Comissionamento”, as obrigações tributárias
              perante os Compradores são exclusivas do Criador, sendo este o
              único beneficiário direto do pagamento realizado pelo Comprador é
              responsável pela emissão de nota(s) fiscal(is) em decorrência da
              venda de seus Produtos aos Compradores. O Coprodutor é responsável
              pelo serviço prestado ao Criador e pela emissão de nota fiscal
              perante o Criador;
            </p>

            <p>
              5.9.1.2. <span className="fw-semibold">Parceria de Negócio</span>:
              na coprodução do tipo “Parceria de Negócio”, as obrigações
              tributárias perante os Compradores são compartilhadas entre
              Criador e Coprodutor, ou seja, sendo ambos reconhecidos como
              beneficiários diretos do pagamento realizado pelo Comprador.
            </p>

            <p>
              5.9.2.{" "}
              <span className="fw-semibold">
                Atribuições exclusivas de Criador
              </span>
              : Independentemente do tipo de coprodução escolhida, os Usuários
              estão cientes que somente o Criador poderá, dentro da Plataforma,
              realizar as atividades gerenciais necessárias e decorrentes do
              mandato concedido por estes Termos, como por exemplo, alterações
              de preço, oferta, demais coproduções, dentre outras atividades
              inerentes ao titular do Produto. Caberá ao Coprodutor, ao acessar
              a Plataforma, verificar se os dados e informações indicados pelo
              Criador estão corretos, e com ele dirimir eventual divergência e
              solicitar sua correção, isentando a Click Rifas de qualquer
              responsabilidade. Veja a Política de Pagamentos da Click Rifas
              para saber mais sobre Coprodução.
            </p>

            <p>
              5.9.3.{" "}
              <span className="fw-semibold">
                Divergências entre Criadores e Coprodutores
              </span>
              : Nenhum contrato, negociação, ajustes ou acordos estabelecidos
              entre Criadores e seus Coprodutores serão administrados, revisados
              ou aprovados pela Click Rifas. Os Usuários são os únicos
              responsáveis pelas escolhas que fizeram no estabelecimento das
              parcerias comerciais, inclusive no tocante ao modelo escolhido, a
              distribuição de papéis e responsabilidades, tal qual as
              consequências jurídicas, tributárias e econômicas dele
              decorrentes. A Click Rifas não se envolve em divergências entre as
              Partes, de nenhuma forma, e não tem obrigação de intervir na
              resolução de quaisquer conflitos.
            </p>

            <p>
              5.10.{" "}
              <span className="fw-semibold">Colaboradores dos Criadores</span>:
              Cabe aos Criadores e Coprodutores estabelecerem entre si as
              condições das suas relações. Os Criadores e Coprodutores são
              integral e solidariamente responsáveis perante terceiros e perante
              a Click Rifas pelas atividades em sua conta ou relativas aos seus
              Produtos. Ao aceitar estes Termos, os Criadores e Coprodutores
              isentam a Click Rifas de responsabilidade por quaisquer danos,
              patrimoniais ou extrapatrimoniais, decorrentes de ações ou
              omissões praticadas pelos Colaboradores.
            </p>

            <p>
              5.11.{" "}
              <span className="fw-semibold">
                Obrigações perante os Compradores
              </span>
              : Lembramos que, quando os Usuários fazem qualquer transação sobre
              um Produto mediante a Plataforma, eles celebram um contrato
              diretamente um com o outro, ainda que o contrato tenha sido
              firmado por meio da Click Rifas, cujo modelo de negócio é o de
              agente comercial. Assim, entre outros aspectos, o Criador e o
              Coprodutor são responsáveis (a) por garantir que o Comprador se
              beneficie do Produto tal como lhe foi ofertado no momento da
              compra; (b) pela entrega do Produto ao Comprador, especialmente se
              o produto for físico ou o uso for disponibilizado por meio de
              ferramentas externas à Plataforma; (c) por solucionar quaisquer
              problemas que ocorram com o Produto após a entrega ao Comprador,
              como os problemas técnicos, troca e devolução; (d) por fornecer
              suporte adequado ao Comprador; (e) por responder aos eventuais
              contatos da equipe de suporte da Click Rifas para viabilizar o
              atendimento adequado aos Usuários; e (f) por definir o preço final
              do Produto que será cobrado do Comprador. Em especial, o Criador e
              o Coprodutor devem respeitar os prazos de exercício de direito de
              arrependimento e de garantia exigidos por lei, se aplicáveis, bem
              como qualquer prazo adicional que oferecer aos Compradores,
              obrigando-se a aceitar eventual solicitação de cancelamento da
              venda e reembolso, troca ou devolução do Produto, por meio da
              Plataforma, ou de seus canais de atendimento e de suporte, quando
              aplicável. Para tanto, o Criador e o Coprodutor desde já autorizam
              a Click Rifas a realizar a dedução dos valores de reembolso
              devidos por ele a qualquer Comprador. A Click Rifas pode deduzir
              esses valores dos créditos existentes ou futuros na conta do
              Criador e Coprodutor.
            </p>

            <label className="fw-medium">
              6. AS PRINCIPAIS REGRAS SOBRE O PROGRAMA DE AFILIADOS
            </label>
            <p>
              6.1.{" "}
              <span className="fw-semibold">
                Responsabilidade pelos Afiliados
              </span>
              : O Criador e o Coprodutor, em casos de coprodução no modelo de
              Parceria de Negócio, são integralmente responsáveis pela atuação
              dos afiliados por ocasião da promoção das vendas de seu Produto.
              Se qualquer afiliado adotar condutas inadequadas, ou praticar
              ações ou omissões contrárias a estes Termos ou às Políticas da
              Click Rifas, a Click Rifas pode aplicar quaisquer das medidas
              previstas na Seção 10 destes Termos, de maneira isolada ou
              cumulativa, em desfavor do Afiliado, do Criador, do seu Produto ou
              de todos. São consideradas condutas inadequadas dos Afiliados,
              entre outras, os seguintes exemplos: (a) a mudança da narrativa de
              vendas do Produto; (b) a prestação de informações falsas,
              incompletas ou imprecisas sobre quaisquer características do
              Produto; (c) a prática de spam ou qualquer prática que a Click
              Rifas entender nociva ao mercado de afiliação ou ao Programa de
              Afiliados.
            </p>

            <p>
              6.1.2.{" "}
              <span className="fw-semibold">
                Práticas Vedadas aos Criadores
              </span>
              : O Criador não pode criar outros mecanismos de pagamento de
              comissão, compensação, bonificação, pagamento direto ou
              remuneração a qualquer título realizados fora da Plataforma. O
              Criador também não pode exigir como requisitos para aceitar
              candidatos ou mantê-los como Afiliados que atinjam metas, (b)
              comprem previamente qualquer Produto ou (c) paguem qualquer valor
              ao Criador.
            </p>

            <p>
              6.1.3. <span className="fw-semibold">Comunicação</span>: O Criador
              deve comunicar ao Afiliado ou à sua rede de Afiliados, com
              antecedência mínima de 03, 30, 60 ou 90 dias, conforme sua
              escolha, todas as condições de afiliação e qualquer mudança que
              fizer sobre as regras aplicáveis à afiliação como, por exemplo, a
              sua interrupção ou mudanças nos percentuais de comissão, salvo se
              ficar demonstrado o descumprimento, pelo Afiliado, das regras
              previamente estabelecidas.
            </p>

            <p>
              6.2. <span className="fw-semibold">Regras de Afiliação</span>: Ao
              aderir ao Programa de Afiliados, o Criador deve observar as
              determinações mínimas estabelecidas nestes Termos, sendo de
              atribuição exclusiva do Criador: (a) determinar o percentual
              devido ao Afiliado pela venda de cada Produto; (b) estabelecer os
              critérios de participação do Programa de Afiliados para seus
              Produtos; (c) aceitar ou recusar os candidatos inscritos, conforme
              critérios previamente estabelecidos; (d) definir as formas e
              limitações de divulgação do Produto; e (d) fazer a gestão de sua
              rede de Afiliados. A Click Rifas se reserva o direito de limitar o
              número de Afiliados de determinado Produto ou Criador, bem como o
              direito de acrescentar deveres e obrigações aos Criador e
              Afiliados adicionais aos previstos nestes Termos, a qualquer
              tempo, com ou sem aviso prévio. Na hipótese de conflito entre as
              regras estabelecidas pelos Criador e as condições destes Termos,
              estes últimos devem sempre prevalecer.
            </p>

            <p>
              6.3. <span className="fw-semibold">Processo de Afiliação</span>: O
              Afiliado deve apresentar candidatura para afiliação a determinado
              Produto específico. O Criador pode aceitá-la ou recusá-la com base
              em seus critérios previamente definidos, salvo nos casos em que a
              afiliação não esteja condicionada à prévia aprovação. Ao submeter
              a sua candidatura, o Afiliado concorda com todas as regras
              estabelecidas pelo Criador, inclusive com relação aos valores e às
              regras de divisão da comissão pela venda do Produto, que são
              determinadas exclusivamente pelo Criador, sem a participação de
              Coprodutor ou da Click Rifas. O Afiliado deve levar em conta todas
              as condições, gerais e específicas, da Click Rifas e do Criador,
              antes de decidir se engajar na promoção de um determinado Produto.
            </p>

            <p>
              6.4. <span className="fw-semibold">Condição de Afiliado</span>: A
              afiliação a um Produto não estabelece qualquer relação entre o
              Afiliado e a Click Rifas além daquela de Usuário. O Afiliado não
              pode praticar qualquer ação ou omissão que, direta ou
              indiretamente, o identifique com a Click Rifas ou possa sugerir
              vínculo de qualquer outra natureza com a Click Rifas, seja pelo
              uso indevido da marca ou de qualquer outra propriedade intelectual
              da Click Rifas, seja pela descrição de cargos ou relação
              profissional, ou por qualquer outro meio. Por exemplo, o Afiliado
              não pode criar perfis em redes sociais, de cunho pessoal ou
              profissional, se apresentando como “vendedor da Click Rifas” ou
              “agente da Click Rifas”.
            </p>

            <p>
              6.5.{" "}
              <span className="fw-semibold">
                Divulgação e Comercialização dos Produtos
              </span>
              : O Afiliado é responsável por reproduzir ao público para o qual
              divulgar o Produto todas as características do Produto e as
              condições de venda inseridas pelo Criador na Plataforma. O
              Afiliado assume perante o Comprador responsabilidade solidária com
              o Criador pelo cumprimento integral das condições de venda.
            </p>

            <p>
              6.6.{" "}
              <span className="fw-semibold">
                Sub-Afiliação ou Marketing Multinível
              </span>
              : O Afiliado não pode criar ou praticar, direta ou indiretamente,
              qualquer forma de revenda ou sub-afiliação, de marketing
              multinível ou dinâmicas similares.
            </p>

            <label className="fw-medium">
              7. A EXPERIÊNCIA DE COMPRA DO COMPRADOR
            </label>
            <p>
              7.1.{" "}
              <span className="fw-semibold">
                Responsabilidade pela Experiência do Comprador
              </span>
              : O Click Rifas pode ser utilizado por qualquer Criador
              individualmente ou em conjunto com outro Coprodutor. A Click Rifas
              também preza pela autonomia das pessoas, acreditando que seus
              Usuários devem ter o poder de decisão sobre quais Produtos desejam
              rifar. Por isso, a Click Rifas não se obriga a fazer controle
              prévio (editorial ou de qualquer outra natureza) ou curadoria dos
              Produtos. A Click Rifas pode fazer verificações sobre o cadastro
              dos Produtos, mas não é capaz de determinar se os Produtos
              observam todas as determinações legais, se são adequados para os
              fins a que se propõem, nem é capaz de determinar a veracidade, a
              exatidão e a completude das informações prestadas pelos Criadores
              e Coprodutores. Ao adquirir algum Produto, o Comprador deve
              verificar a adequação do Produto e do Criador e Coprodutor aos
              fins buscados por ocasião da compra, bem como a veracidade das
              demais informações e características prestadas em relação ao
              Produto. Além disso, o Comprador assume voluntariamente todo e
              qualquer risco associado à aquisição do Produto. Isso inclui o
              risco de ser exposto a assunto ou mercadoria que considere
              ofensivo, indecente ou censurável, ou de que o conteúdo do Produto
              veiculado possa resultar em efeitos danosos ou adversos, físicos
              ou mentais, pelos quais o Comprador assume total responsabilidade.
            </p>

            <p>
              7.2.{" "}
              <span className="fw-semibold">
                Diligência sobre as Informações Prestadas
              </span>
              : O Comprador é integralmente responsável por todas as informações
              que prestar e pelas declarações que fizer aos Criadores ou a
              qualquer outra pessoa por ocasião do uso da Plataforma, em razão
              da compra ou em decorrência do uso de qualquer Produto.
            </p>

            <label className="fw-medium">8. A REMUNERAÇÃO DA Click Rifas</label>
            <p>
              8.1.{" "}
              <span className="fw-semibold">Taxas do Criador e Coprodutor</span>
              : O uso pelo Criador e pelo Coprodutor do modelo “Parceria de
              Negócio”, dos recursos que a Plataforma disponibiliza, está
              sujeito ao pagamento das Taxas de Licença e de Serviço, que são
              cobradas por ocasião da venda de cada Produto, no momento em que a
              transação é aprovada, e deduzidas do valor final cobrado pelo
              Criador e/ou Coprodutor do modelo “Parceria de Negócio” de cada
              Comprador. Essas taxas, seus respectivos meios de pagamento,
              faturamento e tipo de pagamento estão estabelecidos na Política
              Geral de Pagamentos da Click Rifas, e podem ser alteradas a
              qualquer tempo, a critério da Click Rifas, mediante notificação
              prévia.
            </p>

            <p>
              8.2. <span className="fw-semibold"></span>Taxas do Afiliado e
              Comprador: Os Compradores e Afiliados podem acessar e usar os
              serviços da Click Rifas gratuitamente. Os Afiliados prestam
              serviços exclusivamente aos Criadores e Coprodutores, quando
              aplicável. No entanto, a Click Rifas pode cobrar valores
              decorrentes de operações financeiras realizadas por esses
              Usuários, como a antecipação de recebíveis solicitada por
              Afiliado, ou o parcelamento ou uso de diferentes meios de
              pagamento por Comprador, conforme estabelecido na{" "}
              <a
                href="https://hotmart.com/pt-br/legal/politicas-de-pagamento"
                target="_blank"
                rel="noopenner noreferrer"
              >
                Política Geral de Pagamentos
              </a>{" "}
              da Click Rifas.
            </p>

            <label className="fw-medium">
              9. LIMITES DE RESPONSABILIDADE DA Click Rifas
            </label>
            <p>
              9.1.{" "}
              <span className="fw-semibold">
                Limites da Responsabilidade da Click Rifas
              </span>
              : Lembramos que, ao usar a Plataforma como Criador, Coprodutor,
              Afiliado ou Comprador, você é um contratante dos serviços
              prestados pela Click Rifas. A Click Rifas não cria, elabora,
              controla, endossa, vende ou fornece qualquer Produto para você. Os
              Criadores e os Coprodutores são os totalmente responsáveis pelo
              Produto e pelas informações que lhe são inerentes. Ao aceitar
              estes Termos e usar a Plataforma, você o faz voluntariamente, por
              sua conta e risco. Por isso, excetuadas as hipóteses de
              responsabilidade determinadas por lei, a Click Rifas não responde
              por danos de qualquer natureza decorrentes do acesso ou uso da
              Plataforma, sejam patrimoniais ou extrapatrimoniais, diretos ou
              indiretos, prejuízos efetivos ou lucros cessantes. A Click Rifas
              não responde, a título exemplificativo e não exaustivo, por: (a)
              danos decorrentes da inadequação dos Produtos aos fins a que se
              destinam; (b) danos decorrentes de defeitos ou vícios de qualidade
              ou quantidade dos Produtos; (c) danos decorrentes de riscos
              relativos ao uso de Produto, inclusive potencial nocividade ou
              periculosidade; (d) reclamações decorrentes de insatisfações pela
              baixa qualidade dos Produtos; (e) danos decorrentes de erro,
              incompletude, inverdade ou inadequação de informações prestadas na
              divulgação de Produto mediante recursos disponibilizados pela
              Plataforma; (f) danos relacionados à prazo e forma de entrega,
              troca, devolução e/ou extravio dos Produtos, (g) danos decorrentes
              do descumprimento, pelos Usuários, dos seus deveres legais ou
              contratuais perante outros Usuários; (h) danos decorrentes de
              erro, incompletude, inverdade ou inadequação de informações
              prestadas durante o processamento de transações de pagamento; (i)
              danos decorrentes dos Serviços de Terceiros; (j) danos decorrentes
              do uso indevido da Plataforma por terceiros ou Usuários, sejam
              Criadores, Coprodutores, Afiliados ou Compradores, a que título
              for, especialmente em caso de divulgação em outras plataformas de
              Produtos previamente adquiridos; (k) danos decorrentes dos
              materiais, informações ou conteúdos publicados em sites de
              terceiros, tampouco pela disponibilidade destes sites, competindo
              ao Usuário usar seu próprio discernimento, precaução e senso comum
              ao acessar sites de terceiros, devendo conferir as respectivas
              políticas de privacidade e os termos de uso aplicáveis; (l) danos
              decorrentes de dificuldades técnicas ou falhas nos sistemas,
              servidores ou na internet; (m) danos decorrentes de ataques de
              vírus ao equipamento do Usuário ocorridos durante o uso da
              Plataforma, ou como consequência da transferência de dados,
              arquivos, imagens, textos ou áudio ou (n) danos decorrentes da
              disponibilização não autorizada de Produtos, no todo ou em parte,
              na Plataforma ou outras páginas e aplicativos de internet, por
              Usuários ou por terceiros, em razão de atividades de pirataria,
              tais como, mas não limitadas à cópia, download indevido,
              modificação, reprodução, distribuição, armazenamento, transmissão,
              venda, revenda e engenharia reversa de conteúdos.
            </p>

            <p>
              9.2.{" "}
              <span className="fw-semibold">
                Exclusão da Click Rifas do Polo Passivo de Eventual Disputa
              </span>
              : Ao aceitar estes Termos, você desde já concorda com a exclusão
              da Click Rifas do polo passivo de qualquer tipo de reclamação ou
              processo, judicial ou extrajudicial, que (i) você iniciar contra
              outros Usuários ou terceiros e/ou (ii) você receber de outros
              Usuários ou terceiros, a respeito do uso da Plataforma e/ou
              relacionado aos seus Produtos. Essa exclusão também se aplica em
              favor dos sócios da Click Rifas, dos seus controladores, das suas
              sociedades controladas ou coligadas, dos seus administradores,
              diretores, gerentes, empregados, agentes, colaboradores,
              representantes e procuradores.
            </p>

            <label className="fw-medium">
              10. INFRAÇÕES AOS TERMOS E ÀS POLÍTICAS DA Click Rifas
            </label>
            <p>
              10.1. <span className="fw-semibold">Infrações aos Termos</span>:
              Se você deixar de observar qualquer condição destes Termos ou de
              qualquer Política da Click Rifas, total ou parcialmente, a Click
              Rifas pode aplicar diferentes medidas, a seu exclusivo critério,
              de forma isolada ou cumulativa, com ou sem prévio aviso, a
              qualquer tempo e pelo período que lhe aprouver. Entre essas
              medidas, sem prejuízo de outras não previstas nestes Termos, a
              Click Rifas pode aplicar: (a) advertência; (b) limitação, remoção
              ou encerramento de acesso ao Produto; (c) retirada ou diminuição
              de visibilidade do Produto do Click Rifas ou qualquer outro meio
              de divulgação; (d) limitação de acesso, suspensão ou desligamento
              do Programa de Afiliados; (e) limitação de acesso, suspensão ou
              encerramento de qualquer benefício ou categoria especial oferecida
              pela Plataforma relacionada ao Criador e Coprodutor, à sua conta,
              ou a determinado Produto; (f) limitação, suspensão ou encerramento
              de acesso a alguma funcionalidade especial da Plataforma; (g)
              remoção da página do Produto; (h) limitação de acesso, suspensão,
              bloqueio ou remoção de Produto, conta ou Usuário; ou (i)
              encerramento deste Contrato.
            </p>

            <p>
              10.2. <span className="fw-semibold">Medidas Preventivas</span>: A
              Click Rifas pode, a seu critério, adotar medidas preventivas para
              averiguar possíveis infrações aos Termos ou às Políticas da Click
              Rifas, ou se entender ser necessário para proteger interesses
              próprios ou de terceiros. Essas medidas podem durar o tempo
              necessário para que eventual correção ou verificação seja feita,
              ou até a Click Rifas decidir pelo encerramento ou não dos Serviços
              da Click Rifas, ou pela aplicação de outras medidas. As medidas
              preventivas podem resultar na suspensão temporária dos Serviços da
              Click Rifas a determinado Produto ou Usuário. Nessa hipótese,
              durante todo o período de suspensão, a Click Rifas pode alterar ou
              retirar o acesso total ou parcial do Usuário, dentre as quais, a
              título exemplificativo: (a) a Click Rifas pode suspender a remessa
              de valores transacionados oriundos de vendas realizadas mediante a
              Plataforma, ou impedir o Usuário de realizar qualquer resgate de
              valores acumulados na sua conta, conforme a Política de Pagamento
              da Click Rifas; ou (b) a Click Rifas pode impedir o Usuário de
              alterar dados cadastrais, dados bancários e os Produtos
              cadastrados. A Click Rifas também pode cancelar links de vendas e
              ofertas ativas em caso de suspensão dos Serviços da Click Rifas.
              Nessas hipóteses, o Usuário não tem direito a indenização de
              quaisquer danos, patrimoniais ou extrapatrimoniais, decorrentes
              das medidas preventivas aplicadas pela Click Rifas.
            </p>

            <p>
              10.3.{" "}
              <span className="fw-semibold">
                Outras Medidas Judiciais ou Extrajudiciais
              </span>
              : A Click Rifas pode adotar quaisquer outras medidas, judiciais ou
              extrajudiciais, que entender cabíveis, em nome próprio, dos
              Usuários ou mediante terceiros legitimados, sem prejuízo da adoção
              isolada ou cumulativa das outras mencionadas nos itens anteriores.
            </p>

            <label className="fw-medium">
              11. SUSPENSÃO OU ENCERRAMENTO DOS SERVIÇOS DA Click Rifas
            </label>
            <p>
              11.1.{" "}
              <span className="fw-semibold">
                Suspensão ou Encerramento dos Serviços da Click Rifas
              </span>
              : Os valores e princípios da Click Rifas são amparados no respeito
              ao próximo e à liberdade, e estamos sempre empenhados em facilitar
              o empreendedorismo, o conhecimento e a educação para que mais
              pessoas atinjam desenvolvimento pessoal e profissional. Por isso,
              a Click Rifas se reserva o direito de tomar diversas ações
              destinadas a proteger a Plataforma ou nossa comunidade, mediante
              requerimento justificado de terceiro, por iniciativa própria ou em
              nome dos Usuários. Essas medidas podem incluir suspender ou
              interromper a prestação de Serviços, encerrar a licença de uso
              concedida a Usuário, tornar Produto indisponível, removê-lo da
              Plataforma, bem como quaisquer outras medidas previstas ou não na
              Seção 10 destes Termos. A Click Rifas pode decidir tomar essas e
              outras medidas a qualquer tempo, com ou sem aviso prévio, de modo
              isolado ou cumulativo. Qualquer decisão da Click Rifas a esse
              respeito ocorrerá a seu exclusivo critério, por motivos que podem
              incluir: (a) a inobservância destes Termos ou das Políticas da
              Click Rifas; (b) a prática de ato que contrarie de alguma maneira
              os valores ou princípios da Click Rifas; (c) qualquer conduta,
              praticada dentro ou fora da Plataforma, que possa impactar
              negativamente a Click Rifas, ou que possa levar a empresa ou seus
              negócios a descrédito público, desonra, escândalo ou ridículo. O
              fato de qualquer Produto ou Usuário ter sido habilitado
              anteriormente durante o processo de verificação cadastral não
              impede a Click Rifas de tomar as medidas que julgar adequadas,
              quando entender cabível. Ao aceitar estes Termos, você reconhece
              que não tem direito a indenização de qualquer dano, patrimonial ou
              extrapatrimonial, se a Click Rifas adotar qualquer medida baseada
              em lei, nestes Termos ou nas Políticas da Click Rifas, inclusive
              se você tiver seu cadastro ou qualquer Produto recusado, se tiver
              o acesso à sua conta suspenso ou interrompido, se a Click Rifas
              encerrar a licença conferida a você para uso da Plataforma, ou se
              você tiver um Produto removido da Plataforma ou tornado
              indisponível.
            </p>

            <p>
              11.2. <span className="fw-semibold">Encerramento Imotivado</span>:
              Ao aceitar este Contrato, a Click Rifas lhe concede
              automaticamente uma licença de uso não exclusiva da Plataforma,
              por prazo indeterminado. Desse modo, a Click Rifas pode encerrar
              este Contrato e a respectiva licença de uso a qualquer momento, a
              critério da Click Rifas, mediante o envio de notificação com 30
              dias de antecedência para o endereço de e-mail que você registrar
              na sua conta.
            </p>

            <p>
              11.3. <span className="fw-semibold">Encerramento Motivado</span>:
              A Click Rifas pode encerrar este Contrato imediatamente, sem aviso
              prévio, (a) se você violar estes Termos ou quaisquer condições das
              Políticas da Click Rifas; (b) para prevenir fraudes; (c) para
              impedir a ocorrência ou o agravamento de danos a si ou a
              terceiros; ou (d) em razão de estrito cumprimento de dever legal,
              por decisão judicial ou determinação de órgão governamental ou
              agência regulatória.
            </p>

            <p>
              11.3.1.{" "}
              <span className="fw-semibold">
                Encerramento Imediato por Condutas Inadequadas
              </span>
              : A Click Rifas leva a integridade da comunidade que usa a
              Plataforma muito a sério. Assim, a Click Rifas pode aplicar
              quaisquer das medidas previstas na Seção 10 destes Termos, ou, se
              entender necessário, pode inclusive encerrar este Contrato
              imediatamente, total ou parcialmente, sem aviso prévio, (a) se a
              Click Rifas tomar ciência de reclamações sobre conduta
              inapropriada sua praticada de maneira reiterada ou contumaz; (b)
              se o seu Produto ou se você, como Criador ou Coprodutor, receber
              um volume de reclamações nos canais de atendimento, estelionato,
              reclamações sobre não entrega de prêmios, prática de golpes e
              outros atos que a Click Rifas entender incompatível com a
              qualidade que se espera de Usuários ou Produtos cadastrados na
              Plataforma; (c) se você, como Criador, Coprodutor ou Afiliado,
              deixar de prestar suporte aos Compradores; (d) se você se recusar
              a corrigir, incluir ou excluir informação relevante sobre o
              Produto ou sobre você mesmo na Plataforma; (e) se os índices de
              chargebacks, reclamações e contestações referentes às transações
              de pagamento do seu produto ou de sua conta, atingirem ou
              ultrapassarem as referências adotadas pelas principais bandeiras e
              demais participantes dos arranjos de pagamentos. A Click Rifas
              também pode recusar a comercialização de produtos pertencentes à
              nichos que possuam, historicamente, altos índices de chargebacks,
              reclamações e contestações, bem como encerrar o contrato
              definitivamente se houver insistência e reincidência na
              comercialização desses produtos; (f) se você fornecer informações
              incompletas, desatualizadas, incorretas, fraudulentas ou
              imprecisas em seu processo de cadastro, sobre o Produto ou para
              receber atendimento pela Click Rifas; e (g) se você fizer algo
              nocivo ou prejudicial à Click Rifas e a Click Rifas entender que o
              encerramento do Contrato seja medida necessária, em bases
              razoáveis, para proteger a saúde, a segurança, a reputação, a
              honra e os direitos da Click Rifas, de colaboradores,
              investidores, sócios, administradores, dos seus Usuários ou de
              terceiros afetados por ação ou omissão que você praticar.
            </p>

            <p>
              11.4. <span className="fw-semibold">Efeitos do Encerramento</span>
              : Se a Click Rifas encerrar este Contrato, interrompendo a
              prestação dos Serviços da Click Rifas, em qualquer modalidade e
              por qualquer razão, você deve observar as condições a seguir:
            </p>

            <p>
              11.4.1.{" "}
              <span className="fw-semibold">Acesso à Conta Click Rifas</span>:
              Se este Contrato for encerrado, você não terá direito a uma
              restauração da sua conta na Plataforma. Você também não poderá
              registrar uma nova conta na Plataforma, nem acessar ou usar a
              Plataforma através da conta de outro Usuário, sob pena de
              banimento do(s) usuários envolvidos na violação.
            </p>

            <p>
              11.4.2.{" "}
              <span className="fw-semibold">
                Fornecimento de Dados Pessoais
              </span>
              : A Click Rifas não é obrigada a fornecer aos Usuários dados
              adicionais aos quais eles já tenham acesso em decorrência do uso
              regular da Plataforma, em observância da Política de Privacidade.
            </p>

            <p>
              11.4.3. <span className="fw-semibold">Mudança de Plataforma</span>
              : A Click Rifas pode, a seu critério e pelo tempo que lhe
              aprouver, em caso de migração dos Compradores de um Produto para
              outra outra plataforma, o Criador e Coprodutor devem providenciar
              essa migração, dentro do prazo que lhe for conferido, com os dados
              dos Usuários aos quais tiver acesso por ocasião do uso da
              Plataforma. A Click Rifas não fornecerá qualquer dado adicional
              àqueles já disponibilizados ao Criador e ao Coprodutor para que a
              migração seja feita.
            </p>

            <p>
              11.4.4.{" "}
              <span className="fw-semibold">
                Resgate de Saldo da Conta Click Rifas
              </span>
              : Se houver saldo na conta do Usuário no momento do encerramento
              do Contrato, os valores devem ser transferidos para a conta
              bancária indicada no cadastro em até 30 (trinta) dias, após a
              solicitação de transferência.
            </p>

            <p>
              11.4.5.{" "}
              <span classname="fw-semibold">
                Reembolso a Compradores Afetados
              </span>
              : Se o encerramento do Contrato de um Criador afetar o acesso de
              Compradores a um determinado Produto, a Click Rifas somente se
              obriga a reembolsar os Compradores até o limite do saldo existente
              na conta de pagamento do Criador.
            </p>

            <p>
              11.4.6.{" "}
              <span className="fw-semibold">
                Retenção de Valores pela Click Rifas
              </span>
              : Se a Click Rifas encerrar o Contrato, por qualquer motivo, com
              um Criador, Coprodutor ou Afiliado, a Click Rifas pode reter um
              montante do saldo do Criador, Coprodutor ou Afiliado na Plataforma
              por período de até 180 (cento e oitenta) dias contados a partir da
              última transação realizada. Essa retenção deve ter como finalidade
              assegurar os direitos de regresso e compensação da Click Rifas por
              quaisquer despesas que ela suportar e que sejam de
              responsabilidade dos Rifeiros, Criadores, Coprodutores ou
              Afiliados. Após esse período, a Click Rifas deve liberar o saldo
              na conta bancária de cadastro do Criador, Coprodutor ou Afiliado.
            </p>

            <p>
              11.4.7.{" "}
              <span className="fw-semibold">
                Suporte aos Usuários após o Encerramento
              </span>
              : O suporte ao Criador e ao Coprodutor e o respectivo acesso ao
              gerente de conta, caso existente, serão automaticamente encerrados
              com o encerramento do Contrato. Havendo necessidade, os Usuários
              interessados devem endereçar quaisquer dúvidas ou problemas
              remanescentes ou decorrentes do encerramento ao serviço de suporte
              ao cliente da Click Rifas.
            </p>

            <p>
              11.5.{" "}
              <span classname="fw-semibold">
                Cláusulas Sobreviventes ao Encerramento
              </span>
              : As Seções 5, 6, 7 e 9 destes Termos devem sobreviver a qualquer
              encerramento deste Contrato.
            </p>

            <label className="fw-medium">
              12. MECANISMOS DE DENÚNCIAS E SOLUÇÃO DE DISPUTAS
            </label>
            <p>
              12.1. <span className="fw-semibold">Mecanismos de Denúncias</span>
              : A Click Rifas tem 2 canais para que quaisquer interessados
              possam reportar violação de direitos e outras irregularidades na
              Plataforma, inclusive infrações a estes Termos e às Políticas da
              Click Rifas, podem ser reportados pelo chat ou através do{" "}
              <a
                href="https://app.clickrifas.com/#/faq"
                target="_blank"
                rel="noopenner noreferrer"
              >
                FAQ
              </a>{" "}
              (sanar dúvidas). Essas denúncias são apuradas com rigor, e podem
              resultar em consequências diversas aos envolvidos. Por isso, é
              muito importante que o canal de atendimento seja utilizado com
              ética. Em caso de dúvida sobre a ocorrência de infração,
              recomendamos que busque orientação jurídica antes de enviar
              qualquer notificação, além de avaliar se existem formas de provar
              o que pretende informar.
            </p>

            <p>
              12.2.{" "}
              <span className="fw-semibold">
                Denúncias sobre violação a direitos de propriedade intelectual
              </span>
              : Se a sua denúncia for relativa a violação de direitos de
              propriedade intelectual, você deve seguir todas as orientações dos
              canais, incluindo o compartilhamento de informações capazes de
              provar os fatos denunciados, em especial: (a) suas informações de
              contato, para que possamos nos comunicar com você; (b) informações
              sobre o conteúdo denunciado, com o detalhamento do ocorrido e os
              documentos demonstrativos dos fatos narrados, no que for possível;
              e (c) informações sobre a titularidade dos direitos de propriedade
              intelectual que possam ter sido violados. A denúncia deve ser
              enviada pela pessoa cujos direitos de propriedade intelectual
              foram violados, ou por representante munido de procuração com
              poderes para tanto. A Click Rifas não aceita denúncias enviadas
              por terceiros. Ao encaminhar uma denúncia, o denunciante declara
              estar ciente e concordar que a Click Rifas compartilhará suas
              informações com o denunciado, e que o denunciado poderá contatar o
              denunciante para esclarecer os fatos e dirimir a questão.
            </p>

            <p>
              12.3.{" "}
              <span className="fw-semibold">
                Denúncias sobre divulgação de cenas de nudez ou atos sexuais de
                caráter privado
              </span>
              : Se a sua denúncia for relativa a violação da intimidade
              decorrente da divulgação, sem autorização de seus participantes,
              de imagens, de vídeos ou de outros materiais contendo cenas de
              nudez ou de atos sexuais de caráter privado, a Click Rifas
              promoverá a indisponibilização desse conteúdo, no âmbito e nos
              limites técnicos do seu serviço, de forma diligente, em até 10
              (dez) dias corridos da data do recebimento pela Click Rifas de
              todas as informações necessárias para que a Click Rifas possa
              tomar essa providência. Para isso, você deve indicar precisamente:
              (a) a URL onde está o material objeto da denúncia; e (b) os meios
              que possibilitem à Click Rifas identificar a vítima no material
              denunciado, conforme sua descrição. É possível denunciar apenas
              conteúdo que envolva você, um familiar ou outra pessoa de quem
              você seja o representante legal, com as comprovações de vínculo ou
              parentesco. Materiais que envolvam outras pessoas devem ser
              denunciados por elas, seus familiares ou responsáveis.
            </p>

            <p>
              12.4.{" "}
              <span className="fw-semibold">
                Providências a serem adotadas pela Click Rifas
              </span>
              : Qualquer denúncia deve ser encaminhada contendo todas as
              informações exigidas nos itens anteriores. A Click Rifas não
              iniciará qualquer processo de verificação se verificar a ausência
              de qualquer daquelas informações. Em caso de denúncias envolvendo
              violações de propriedade intelectual, é vedado o anonimato.
            </p>

            <p>
              12.5.{" "}
              <span className="fw-semibold">
                Responsabilidade do denunciante
              </span>
              : O envio intencional de denúncias falsas ou enganosas pode levar
              à aplicação de qualquer das medidas previstas na Seção 10 destes
              Termos, inclusive o bloqueio definitivo de conta, se o denunciante
              for Usuário da Plataforma, além da sua responsabilidade por danos,
              de acordo com a legislação aplicável. Esclarecemos que
              denunciantes de má-fé podem ser investigados pelas autoridades
              competentes e que Click Rifas coopera com as investigações
              oficiais nesse sentido. O denunciante responde integralmente por
              quaisquer danos que causar ao denunciado, à Click Rifas ou a
              terceiros em razão de denúncias infundadas, principalmente nos
              casos em que a Click Rifas, diante da denúncia, e a seu exclusivo
              critério, bloquear e excluir definitivamente a conta.
            </p>

            <p>
              12.6.{" "}
              <span className="fw-semibold">
                Processo de Apuração de Denúncias
              </span>
              : Se a Click Rifas entender que uma denúncia não tem fundamentos
              ou elementos de prova suficientes para iniciar processo interno de
              apuração, a Click Rifas pode arquivar a denúncia e encerrar a
              ocorrência reportada. Se a Click Rifas entender que a apuração dos
              fatos é necessária, e desde que a denúncia não tenha trazido
              elementos e provas incontestáveis sobre a sua procedência, ela
              pode encaminhar a denúncia ao denunciado ou ao responsável pelo
              Produto denunciado, sem prejuízo de a Click Rifas adotar,
              preventivamente e a seu exclusivo critério, quaisquer das medidas
              previstas na Seção 10 destes Termos, de modo isolado ou
              cumulativo. Se requerido, o denunciado pode responder a denúncia
              em até 5 dias contados do seu encaminhamento pela Click Rifas.
              Nesse caso, denunciado deve enviar: (a) informações de contato;
              (b) informações sobre o conteúdo denunciado; (c) informações sobre
              a titularidade dos direitos de propriedade intelectual sobre o
              Produto denunciado, incluindo a documentação comprobatória
              pertinente; e (d) declaração de discordância sobre a denúncia de
              violação a direitos de propriedade intelectual,
              responsabilizando-se por quaisquer danos causados pela
              continuidade da comercialização ou do acesso ao Produto objeto da
              denúncia.
            </p>

            <p>
              12.6.1. A Click Rifas pode iniciar procedimento de investigação
              interna, a qualquer tempo, sempre que entender necessário à
              apuração dos fatos denunciados, sobretudo diante (a) de
              reclamações cujo esclarecimento seja capaz de solucionar a
              potencial disputa entre os Usuários, (b) de fatos que não tenham
              sido narrados de forma clara, mas que tragam indícios de
              irregularidades praticadas pelo denunciado ou (c) de denúncias com
              inconsistências ou indícios indicativos de má-fé, fraude ou dolo
              do denunciante. Os resultados das investigações internas
              conduzidas pela Click Rifas poderão ser compartilhados com as
              autoridades competentes, sempre que exigido por lei ou necessário.
              Se o compartilhamento não for obrigatório por lei, cabe
              exclusivamente à Click Rifas definir se irá divulgar ou não
              quaisquer resultados de sua apuração.
            </p>

            <p>
              12.7.{" "}
              <span className="fw-semibold">
                Avaliação das Denúncias pela Click Rifas
              </span>
              : Se o denunciado não responder a denúncia em 10 (dez) dias, a
              Click Rifas pode aplicar, em caráter definitivo, quaisquer das
              medidas previstas na Seção 10 destes Termos, de modo isolado ou
              cumulativo, se entender cabíveis, dando ciência ao denunciante e
              comunicando as medidas ao Usuário afetado. A Click Rifas também
              pode aplicar quaisquer das medidas previstas na Seção 10 destes
              Termos, de modo isolado ou cumulativo, se, diante da resposta do
              denunciado, concluir que houve infração aos Termos, violação de
              direitos ou outras irregularidades praticadas na Plataforma. Se a
              Click Rifas, por si ou por terceiros por ela determinados,
              entender que a questão denunciada é razoavelmente controversa, a
              Click Rifas pode instaurar um procedimento de solução entre os
              mesmos.
            </p>

            <p>
              12.8.{" "}
              <span className="fw-semibold">
                Solução de Disputas entre Usuários
              </span>
              : Os Serviços da Click Rifas são prestados para conectar os
              Usuários entre si. As disputas que surgem dessas relações devem
              ser resolvidas entre os Usuários e Compradores diretamente,
              inclusive aquelas relativas à qualidade, às garantias e aos
              aspectos técnicos dos Produtos. Com o intuito de facilitar o
              diálogo para solução de disputas, além do canal de denúncias, a
              Click Rifas oferece serviço de suporte aos Usuários, sem custo,
              por meio da{" "}
              <a
                href="https://app.clickrifas.com/#/faq"
                target="_blank"
                rel="noopenner noreferrer"
              >
                FAQ
              </a>{" "}
              e Chat. Uma vez acionado, o serviço de suporte pode entrar em
              contato com os Usuários envolvidos numa disputa. Você concorda que
              deve aplicar seus melhores esforços para responder aos chamados do
              serviço de suporte, com foco na solução do problema, no menor
              tempo possível e dentro dos prazos estabelecidos pela Click Rifas.
              Se não for possível a composição entre os Usuários acerca de uma
              disputa, a Click Rifas pode (mas não se obriga a) encerrar a
              disputa mediante decisão própria, pautada na boa-fé e em
              parâmetros de equidade, considerando as regras destes Termos e das
              Políticas da Click Rifas. A decisão da Click Rifas a respeito da
              disputa, ou de quem ela designar, deve ser acatada de modo
              integral e irrecorrível pelas partes envolvidas.
            </p>

            <label className="fw-medium">13. DISPOSIÇÕES FINAIS</label>
            <p>
              13.1. <span className="fw-semibold">Dúvidas</span>: O site da
              Click Rifas (
              <a
                href="https://www.clickrifas.com/"
                target="_blank"
                rel="noopenner noreferrer"
              >
                ClickRifas.com
              </a>
              ) é a sua fonte primária com relação a esses Termos, às Políticas
              da Click Rifas, aos Serviços da Click Rifas ou a qualquer
              programa, funcionalidade, recurso ou questões em geral
              relacionados à Plataforma. Nenhum terceiro tem o direito ou
              autorização da Click Rifas para falar em nome dela, e todas as
              práticas estabelecidas ou sugeridas por essas pessoas devem ser
              consideradas não oficiais. Se as informações contidas no site pelo{" "}
              <a
                href="https://app.clickrifas.com/#/faq"
                target="_blank"
                rel="noopenner noreferrer"
              >
                FAQ
              </a>{" "}
              não forem suficientes para esclarecer suas dúvidas, entre em
              contato com o nosso suporte através do chat.
            </p>

            <p>
              13.1. <span className="fw-semibold">Foro</span>: Para dirimir
              quaisquer controvérsias oriundas do contrato de prestação de
              serviço entre a plataforma Click Rifas e esses termos de uso, as
              partes elegem o foro da comarca de Goiânia, Goiás, Brasil.
            </p>
          </CModalBody>
        </CModal>
  )
}

export default ModalTermosUso;