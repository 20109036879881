import React, { useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormSelect,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilLockLocked,
  cilUser,
  cilPhone,
  cilBirthdayCake,
} from "@coreui/icons";
import { addToast } from "src/components/Notification";
import { FaIdCard } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import ModalTermosUso from "src/components/ModalTermosUso";
import { cadastrarAfiliado } from "src/providers/afiliado";

const CadastroAssociado = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const [payload, setPayload] = useState({
    nome: "",
    email: "",
    cpfCnpj: "",
    senha: "",
    confirmar_senha: "",
    aceiteTermosUso: false
  });

  const handlePayload = (key, value) => {
    setPayload({ ...payload, [key]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const result = await cadastrarAfiliado({
          ...payload,
          idIndicador: searchParams.get('idAfiliado')
        });
        if (result.status === 201) {
          addToast("success", "Cadastro relizado com sucesso. Confirme seu Email para ativar sua conta");
          setLoading(false);
          navigate("/login");
        }
      } catch (error) {
        setLoading(false);
        addToast(toast.TYPE.ERROR, error.message);
        console.error("Erro: ", error);
      }
    }
  };

  const validate = () => {
    if (!payload.nome) {
      return addToast(
        "error",
        "Campo nome vazio",
        "Todos campos do formulário precisam ser preenchidos"
      );
    }
    if (!payload.email) {
      return addToast(
        "error",
        "Campo email vazio",
        "Todos campos do formulário precisam ser preenchidos"
      );
    } else if (!EMAIL_REGEX.test(payload.email)) {
      addToast(toast.TYPE.ERROR, "Informe um email válido");
      return false;
    }
    if (!payload.cpfCnpj) {
      return addToast(
        "error",
        "Campo CPF vazio",
        "Todos campos do formulário precisam ser preenchidos"
      );
    }
    if (!payload.aceiteTermosUso) {
      return addToast(
        toast.TYPE.ERROR,
        "É preciso aceitar os termos de uso",
        "Todos campos do formulário precisam ser preenchidos"
      );
    }
    if (!payload.senha) {
      return addToast(
        "error",
        "Campo senha vazio",
        "Todos campos do formulário precisam ser preenchidos"
      );
    }
    if (!payload.confirmar_senha) {
      return addToast(
        "error",
        "Campo confirmar senha vazio",
        "Todos campos do formulário precisam ser preenchidos"
      );
    } else if (payload.senha !== payload.confirmar_senha) {
      return addToast(
        "error",
        "Campo confirmar senha está diferente do campo senha"
      );
    }
    return true;
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={9} lg={7} xl={6}>
            <CCard className="mx-4">
              <CCardBody className="p-4">
                <CForm onSubmit={handleSubmit}>
                  <h1>Cadastre-se</h1>
                  <p className="text-medium-emphasis">Crie sua conta</p>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilUser} />
                    </CInputGroupText>
                    <CFormInput
                      placeholder="Nome"
                      autoComplete="username"
                      value={payload.nome}
                      onChange={({ target }) =>
                        handlePayload("nome", target.value)
                      }
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput
                      placeholder="Email"
                      autoComplete="email"
                      value={payload.email}
                      onChange={({ target }) =>
                        handlePayload("email", target.value)
                      }
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <FaIdCard />
                    </CInputGroupText>
                    <InputMask
                      mask={"999.999.999-99"}
                      className="form-control"
                      placeholder="CPF"
                      value={payload.cpfCnpj}
                      autoComplete="cpf"
                      onChange={({ target }) =>
                        handlePayload("cpfCnpj", target.value)
                      }
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Senha"
                      value={payload.senha}
                      autoComplete="new-password"
                      onChange={({ target }) =>
                        handlePayload("senha", target.value)
                      }
                    />
                  </CInputGroup>
                  <CInputGroup className="mb-3">
                    <CInputGroupText>
                      <CIcon icon={cilLockLocked} />
                    </CInputGroupText>
                    <CFormInput
                      type="password"
                      placeholder="Confirmar senha"
                      value={payload.confirmar_senha}
                      autoComplete="new-password"
                      onChange={({ target }) =>
                        handlePayload("confirmar_senha", target.value)
                      }
                    />
                  </CInputGroup>
                  <CRow className="mb-4 p-2 border-none">
                    <label
                      className="form-control d-flex align-items-center"
                      htmlFor="aceiteTermosUso"
                    >
                      <input
                        className="me-1"
                        type="checkbox"
                        id="aceiteTermosUso"
                        name="aceiteTermosUso"
                        checked={payload.aceiteTermosUso}
                        onChange={({ target }) => handlePayload('aceiteTermosUso', target.checked)}
                      />
                      Eu li e concordo com os{" "}
                      <CButton
                        className="p-0 ms-1"
                        color="link"
                        onClick={() => setModalVisible(true)}
                      >
                        termos de uso
                      </CButton>
                    </label>
                  </CRow>
                  <div className="d-grid">
                    <CButton color="success" type="submit" onLoad={loading}>
                      Criar conta
                      {loading ? <CSpinner className="ms-2" size="sm" /> : null}
                    </CButton>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <ModalTermosUso modalVisible={modalVisible} setModalVisible={setModalVisible} />
      </CContainer>
    </div>
  )
}

export default CadastroAssociado;