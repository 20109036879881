import { ID_CLASSE_AFILIADO } from "src/utils/classes";
import provider from "./config";

export const cadastrarAfiliado = async (payload) => {
  const params = {
    nome: payload.nome.trim(),
    email: payload.email.trim(),
    cpfCnpj: payload.cpfCnpj.replaceAll(".", "").replace("-", "").trim(),
    senha: payload.senha.trim(),
    aceiteTermosUso: payload.aceiteTermosUso,
    idIndicador: payload.idIndicador
  }
  try {
    const response = await provider.post("/entidades/inscricao/afiliado", params);
    return response
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }

    throw Error('Erro ao realizar o cadastro');
  }
}

export const atualizaAfiliado = async (id, payload, token) => {
  try {
    const response = await provider.patch(`/entidades/afiliado/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const getExtratoAfiliado = async (id, params, token) => {
  try {
    const response = await provider.get(`/movdispo/extrato/${id}`, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data
  } catch (error) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const getEntidade = async (query, token) => {
  try {
    const response = await provider.get("/entidades", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        ...query,
        idClasse: ID_CLASSE_AFILIADO
      }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const getAfiliados = async (query, token) => {
  try {
    const response = await provider.get("/entidades/afiliados", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { ...query }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const criaSolicitacaoAfiliado = async (body, token) => {
  try {
    const response = await provider.post("/entidades/link-afiliado", body, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }

    throw Error('Erro ao criar solicitação afiliado');
  }
}

export const postLinkAfiliado = async (payload, token) => {
  try {
    const response = await provider.post("/entidades/cadastro/afiliado", payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const putLinkAfiliado = async (id, payload, token) => {
  try {
    const response = await provider.patch(`/entidades/link-afiliado/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const getLinksAfiliados = async (params, token) => {
  try {
    const response = await provider.get('/entidades/link-afiliados', {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}

export const aceitaSolicitacaoAfiliado = async (id, payload, token) => {
  try {
    const response = await provider.patch(`/entidades/aceite-afiliado/${id}`, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (err) {
    if (err.response && err.response.data.message) {
      throw Error(err.response.data.message);
    }
  }
}